import React, { useState, useEffect } from 'react';
import { Box, List, ListItemButton, ListItemIcon, Divider, Drawer, useTheme, Typography, useNavigate } from '../../../collections/Imports';
import { _nav } from './nav/_nav';

const drawerWidth = 240;

function SideBar() {
    const theme = useTheme();
    const [activeMenuItem, setActiveMenuItem] = useState(0);
    const navigate = useNavigate();

    const handleMenuItemClick = (index) => {
        setActiveMenuItem(index);
    };

    const handlePopstate = () => {
        const currentPath = window.location.pathname;
        const activeIndex = _nav.findIndex((item) => item.to === currentPath);
        setActiveMenuItem(activeIndex);
    };

    useEffect(() => {
        // Set the initial active menu item based on the current path
        handlePopstate();

        // Add an event listener for the popstate event
        window.addEventListener('popstate', handlePopstate);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('popstate', handlePopstate);
        };
    }, []);

    return (
        <Box sx={{ zIndex: '-1' }}>
            <Drawer variant="permanent" sx={{ width: '8%' }}>
                <Divider style={{ background: '#11B295 !important' }} />
                <List sx={{ backgroundColor: '#0E356C', height: '100vh', marginTop: '3rem', overflowX: 'hidden' }}>
                    {_nav.map((sidebarmenus, index) => {
                        const isActive = activeMenuItem === index;
                        return (
                            <React.Fragment key={sidebarmenus.name}>
                                <Typography
                                    onClick={() => {
                                        navigate(sidebarmenus.to);
                                    }}
                                    disablePadding
                                    sx={{
                                        ':hover': {
                                            backgroundColor: '#ffffff1a',
                                            color: '#000!important'
                                        }
                                    }}
                                >
                                    <ListItemButton
                                        onClick={() => handleMenuItemClick(index)}
                                        sx={{
                                            padding: '6px',
                                            minHeight: 48,
                                            maxWidth: 80,
                                            justifyContent: 'center',
                                            display: 'flex',
                                            alignItems: 'center',
                                            backgroundColor: isActive ? '#3E7DFD' : 'transparent',
                                            color: isActive ? '#fff' : 'transparent',
                                            borderRadius: '2px',
                                            borderRight: isActive ? '4px solid #FFF' : 'none',
                                            textDecoration: isActive ? 'none !important' : 'none !important',
                                            mt: 2,
                                            mb: 2,
                                            ':hover': {
                                                backgroundColor: 'transparent',
                                                color: '#000!important'
                                            }
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                justifyContent: 'center',
                                                flexDirection: 'column',
                                                color: isActive ? '#FFF' : 'white',
                                                alignItems: 'center',
                                                fontSize: '10px'
                                            }}
                                        >
                                            {sidebarmenus.icon && <img className="menu-icon" alt="icon-img" src={sidebarmenus.icon} />}
                                            <Typography className="menu-text" sx={{ color: '#fff!important' }}>
                                                {sidebarmenus.name}
                                            </Typography>
                                        </ListItemIcon>
                                    </ListItemButton>
                                </Typography>

                                {index !== _nav.length - 1 && (
                                    <Divider
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            margin: 'auto',
                                            width: '16px',
                                            height: '1px',
                                            color: 'rgba(255, 255, 255, 0.20)',
                                            borderColor: `rgba(255, 255, 255, 0.20)`
                                        }}
                                    />
                                )}
                            </React.Fragment>
                        );
                    })}
                </List>
                <Divider />
            </Drawer>
        </Box>
    );
}

export default SideBar;
