import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import ProtectedRoute from "./ProtectedRoute";
import { lazy, Navigate } from "../collections/Imports";
import NotFound from "views/notFound/NotFound";

const Dashboard = Loadable(lazy(() => import("views/dashboard")));

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <Navigate to="/dashboard" replace />,
    },
    {
      path: "/dashboard",
      element: (
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      ),
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ],
};

export default MainRoutes;
