import {
  DashboardIcon,
  //   MisIcon,
  //   DropOffIcon,
  //   LenderIcon,
  //   LeadsIcon,
  //   SettingIcon,
  //   ScheduleIcon,
  //   CrmIcon,
} from "../../../../collections/Imports.js";

export const _nav = [
  {
    _tag: "MenuItem",
    name: "Dashboard",
    to: "/dashboard",
    icon: DashboardIcon,
  },
];
