import { configureStore } from "@reduxjs/toolkit";

/**------------DASHBOARD----------------------------------- */
import { kpiStatsSlice} from "./dashboard/kpiStatsSlice";
import {publisherTableStatsSlice} from "./dashboard/publisherTableStatsSlice";

/*-----------------------------------------------------------*/
const ldsStore = configureStore({
  reducer: {
    kpiStats: kpiStatsSlice.reducer,
    pubStats: publisherTableStatsSlice.reducer
  },
});
export default ldsStore;
