const apiEndPointsConfig = {
  // lenders

  getLenderNameFilters: "/lds/lenders/name-filters",
  getLenderStatusFilters: "/lds/lenders/status-filters",
  // getPunchinFilters: '/lds/leads/lead-report',
  getLenders: "/lds/lenders/get-lenders",
  getLender: "/lds/lenders/get-lender",
  setLenderPriority: "/lds/lenders/set-lender-priority",
  setLenderStatus: "/lds/lenders/set-lender-status",
  LenderKpi: "/lds/lenders/lender-kpi",
  ResponseTime: "/lds/lenders/lender-response-time",
  LenderStatsGrid: "/lds/lenders/lender-stats",

  // leads

  getFlexMonster: "/lds/leads/flex-monster",
  getLeadReport: "/lds/leads/lead-report",
  nonEligibleLeads: "/lds/leads/non-eligible-leads",
  DEDUPE_LEADS: "/lds/leads/dedupe-leads",
  LEAD_LOGS: "/lds/leads/lead-logs",
  LEAD_LOGS_DETAILS: "/lds/leads/lead-log-detail",

  // insights

  LenderLeadStatus: "/lds/insights/lender-lead-status",
  LenderPerformingStatus: "/lds/insights/lender-performing-status",
  PeekAndMap: "/lds/insights/peek-and-map",
  LeadsStats: "/lds/insights/leads-stats",
  sourcePerformance: "/lds/insights/sources-performance",

  // public

  login: "/lds/public/agent-login",

  //status

  getStatus: "/lds/status/get-status",
  createStatus: "/lds/status/create-status",
  misupload: "/lds/uploads/mis-upload",
  updateStatus: "/lds/status/update-status",

  //drop-off

  getdropoffs: "/lds/marketing/get-drop-offs",
  dropOffKPI: "/lds/marketing/get-dropoff-kpi",
  getUtmSource: "/lds/marketing/get-utm-sources",
  getUtmWiseStats: "/lds/marketing/utm-wise-stats",
  getStageWiseStats: "/lds/marketing/stage-wise-stats",
  gridStages: "/lds/marketing/stage-drop-offs",

  //exit-page-tracker

  getExitPagelenders: "/lds/lenders/get-exit-page-lenders",
  setExitPageStatus: "/lds/lenders/set-exit-lender-status",
  getClickDetails: "/lds/lenders/get-click-details",
  getImpression: "/lds/lenders/get-impression",

  //settings
  getUnSubscriberUser: "/lds/users/get-unSubs-User", //all unsub users
  createUnSubscriberUser: "/lds/users/unSubs-user", //create selected user
  getUserlistByContacts: "/lds/users/get-user-byContact", //get by contact
  removeUnsubscribeUSer: "/lds/users/remove-UnSubs-user", //remove

  // Schedule
  getSchedulerData: "/lds/scheduler/get-scheduler",
  playPause: "/lds/scheduler/play-pause",
  getSchedulerModules: "/lds/scheduler/scheduler-modules",
  createScheduler: "/lds/scheduler/create-scheduler",
  updateScheduler: "/lds/scheduler/update-scheduler",

  // Punchin
  PunchInKPI: "/lds/punchin/punchin-kpi",
  PunchInLenderStatus: "lds/punchin/punchin-lender-status",

  //crm---
  //return all CallDispositions
  getCallDispositions: "/lds/crm/get-call-dispositions",
  getCmCrm: "/lds/crm/get-cm-crm-status",
  // create new CmCrmStatus
  createCmCrmStatus: "/lds/crm/create-cm-crm-status",
  //create new CallDisposition
  createNewCallDisposition: "/lds/crm/add-call-disposition",
  //
  updateCallDispositions: "/lds/crm/update-call-disposition",
  updateCmCrmStatus: "/lds/crm/update-cm-crm-status",
  // uploads
  crmupload: "/lds/uploads/crm-upload",

  //parteners

  getkpiStats: "/lds/partners/get-kpis",
  getTableStats: "/lds/partners/get-stats",
  getUtmSource: "/lds/partners/get-utms",
};

export default apiEndPointsConfig;
