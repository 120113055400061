import {
    Box,
    Button,
    Divider,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Stack,
    Tooltip,
    Typography,
    useState
} from '../../collections/Imports';
import './style.css';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Fullscreen } from '@mui/icons-material';
function CommonChart({
    title,
    subtitle,
    children,
    buttonValue,
    onClick,
    icon,
    buttons,
    searchText,
    onSearchChange,
    handleGridItemClick,
    isFullscreen,
    handleMenu,
    setAnchorEl,
    handleClose,
    bgColor,
    TextColor,
    hoverColor,
    addActionIcon,
    addAction,
    AddButtonName,
    onToggleFullscreen
}) {
    const buttonStyles = {
        backgroundColor: `${bgColor ? bgColor : ''}`,
        color: `${TextColor ? TextColor : '#EE9329'}`,
        '&:hover': {
            backgroundColor: 'red' // Set your desired hover color here
        }
    };
    return (
        <Box>
            <Box className="card-background">
                <Stack direction={'row'} sx={{ alignItems: 'center' }}>
                    <Box sx={{ padding: 3, display: 'flex', justifyContent: 'end' }}>
                        <div>
                            <Typography className="title-text">{title ? title : title}</Typography>
                            <Typography className="sub-text">{subtitle ? subtitle : ''}</Typography>
                        </div>

                        <div>
                            <Typography className="sub-text">{buttons ? buttons : ''}</Typography>
                        </div>

                        <div>
                            <Typography className="sub-text">{buttons ? buttons : ''}</Typography>
                        </div>

                        {/* </div> */}
                    </Box>
                    <Box sx={{ flex: 1 }} />
                    {onSearchChange && (
                        <Box mx={2} style={{ marginTop: 20, marginBottom: 20 }}>
                            <label>
                                {/* Search:{' '} */}
                                <input
                                    type="text"
                                    value={searchText}
                                    className="search-input"
                                    onChange={onSearchChange}
                                    placeholder="Search..."
                                />{' '}
                            </label>
                        </Box>
                    )}

                    {addAction && (
                        <Box>
                            <Button
                                startIcon={addActionIcon}
                                onClick={addAction}
                                sx={{
                                    borderRadius: '20px',
                                    border: '1px solid #DDE1E6',
                                    background: '#FFF',
                                    color: '#EE9329',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    fontFamily: 'Inter',
                                    fontSize: '13px',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    lineHeight: 'normal',
                                    marginRight: '1rem'
                                }}
                            >
                                {AddButtonName}
                            </Button>
                        </Box>
                    )}

                    {buttonValue && (
                        <Box>
                            <Button
                                startIcon={icon}
                                onClick={onClick}
                                style={buttonStyles}
                                sx={{
                                    borderRadius: '20px',
                                    border: '1px solid #DDE1E6',
                                    background: '#FFF',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    fontFamily: 'Inter',
                                    fontSize: '13px',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    lineHeight: 'normal',
                                    marginRight: '1rem'
                                }}
                            >
                                {buttonValue}
                            </Button>
                        </Box>
                    )}

                    <IconButton
                        sx={{
                            mr: 2
                        }}
                        onClick={onToggleFullscreen}
                    >
                        {isFullscreen ? <CloseFullscreenIcon /> : <OpenInNewIcon />}
                    </IconButton>
                </Stack>
                <Divider />
                <Box>{children}</Box>
            </Box>
        </Box>
    );
}

export default CommonChart;
