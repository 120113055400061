import React from 'react';
import { Box, Stack, Typography, InfoOutlined, Button, Divider, Tooltip } from '../../collections/Imports';

const PageHeader = ({ title, pageAction, onBack, backIcon, helpText }) => {
    return (
        <Box sx={{ bgcolor: '#eef2f6', pl: 2 }}>
            <Stack direction="row" sx={{ alignItems: 'center', pl: 4.5 }}>
                <Button style={backIcon ? { minWidth: '30px' } : { display: 'none' }} onClick={onBack}>
                    {backIcon}
                </Button>
                <Typography
                    sx={{
                        color: '#202223',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontFamily: 'Inter',
                        fontSize: '24px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        letterSpacing: '-0.48px',
                        paddingRight: 1
                    }}
                >
                    {title}
                </Typography>{' '}
                <Tooltip title={helpText}>
                    <InfoOutlined sx={{ color: '#87909B' }} />
                </Tooltip>
            </Stack>
            <Divider sx={{ width: '100%', pt: '8px' }} />
        </Box>
    );
};

export default PageHeader;
