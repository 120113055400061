import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  kpiStats: []
};

export const kpiStatsSlice = createSlice({
  name: "kpiLead",
  initialState,
  reducers: {
    getKpiStats: (state, action) => {
      state.kpiStats = action.payload;
    },
  },
});

export const kpiStatsAction = kpiStatsSlice.actions;
