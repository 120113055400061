import { Box, Typography } from '../../collections/Imports';
import './style.css';

function KpiCard(props) {
    const { title, icon, count } = props;
    return (
        <Box className="card-bg">
            <Box display="flex" justifyContent="space-between" gap={'16px'} alignItems={'center'}>
                <Box className="icon">{icon}</Box>
                <Box>
                    <Typography variant="h4" className="title">
                        {title}
                    </Typography>
                    <Typography variant="h2" className="count" pt={'8px'} pl={'4px'}>
                        {count}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

export default KpiCard;
