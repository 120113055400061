import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    pubStats: []
};

export const publisherTableStatsSlice = createSlice({
    name: "pubStats",
    initialState,
    reducers: {
        getPublisherTableStats: (state, action) => {
            state.pubStats = action.payload;
        },
    },
});

export const publisherTableStatsAction = publisherTableStatsSlice.actions;
