import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { useNavigate } from "react-router";
import LogoutIcon from "@mui/icons-material/Logout";
import { Avatar } from "@mui/material";
import { useSnackbar } from "notistack";
import { Stack } from "@mui/system";

import Help from "../../../assets/images/help.svg";

import { LogoImg, Tooltip, Divider } from "../../../collections/Imports";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  var userEmail = localStorage.getItem("username");
  var userName = "";

  if (userEmail) {
    var match = userEmail.match(/^(.+)@/);
    if (match) {
      userName = match[1].charAt(0).toUpperCase() + match[1].slice(1);
    } else {
      console.log("userEmail Error");
    }
  }

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
    enqueueSnackbar("Logout Successfully", { variant: "success" });
  };
  const pubId = localStorage.getItem("pubId");

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          sx={{
            backgroundColor: "#fff",
            height: "48px",
            boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.16)",
            zIndex: 99999,
          }}
          elevation={0}
        >
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1 }}>
              <Box
                direction={"row"}
                sx={{ display: "flex", alignItems: "center", pl: "8px" }}
              >
                <img src={LogoImg} alt="Credmudra partners" className="logo" />
                <Typography className="logo-text">
                  Credmudra partners
                </Typography>
              </Box>
            </Box>
            <Stack direction={"row"}>
              {pubId && (
                <Box>
                  <Button
                    sx={{
                      borderRadius: "20px",
                      border: "1px solid #DDE1E6",
                      background: "#FFF",
                      color: "#EE9329",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "normal",
                      marginRight: "1rem",
                      marginTop: "0.5rem",
                    }}
                  >
                    PUBLISHER ID : {pubId}
                  </Button>
                </Box>
              )}
              <IconButton>
                <Tooltip title="Credmudra partners">
                  <img src={Help} alt="help" />
                </Tooltip>
              </IconButton>
              <IconButton
                onClick={handleMenu}
                sx={{
                  mr: 5,
                }}
              >
                {userEmail && userEmail[0] && (
                  <Avatar
                    sx={{
                      bgcolor: "#0e356c",
                      color: "#fff",
                      fontWeight: "500px",
                      width: 32,
                      height: 32,
                      textTransform: "capitalize",
                    }}
                  >
                    {userEmail[0]}
                  </Avatar>
                )}
              </IconButton>
            </Stack>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              style={{ top: "30px" }}
            >
              <MenuItem>
                <Box>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    pb={2}
                  >
                    {userEmail && userEmail[0] && (
                      <Avatar
                        sx={{
                          bgcolor: "#0e356c",
                          color: "#fff",
                          width: 32,
                          height: 32,
                          textTransform: "capitalize",
                        }}
                      >
                        {userEmail[0]}
                      </Avatar>
                    )}
                    <Box mx={2}>
                      <Box
                        pb={0}
                        color={"#172b4d"}
                        fontWeight={"bold"}
                        sx={{ textTransform: "capitalize" }}
                      >
                        {userName}
                      </Box>
                      <Box>{userEmail || ""}</Box>
                    </Box>
                  </Box>
                  <Divider />
                  <Box display={"flex"} alignItems={"center"} pt={1}>
                    <Button
                      fullWidth={true}
                      variant="outlined"
                      onClick={handleLogout}
                    >
                      <LogoutIcon
                        fontSize={"medium"}
                        style={{ paddingRight: "5px" }}
                      />{" "}
                      Logout
                    </Button>
                  </Box>
                </Box>
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
};

export default Header;
