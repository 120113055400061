import { Outlet, useTheme, AppBar, Box, CssBaseline, Toolbar } from '../../collections/Imports';
import Header from './Header';
import SideBar from './Sidebar/SideBar';

const MainLayout = () => {
    const theme = useTheme();

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default
                }}
            >
                <Toolbar>
                    <Header />
                    <SideBar />
                </Toolbar>
            </AppBar>
            <Box sx={{ ...theme.typography.mainContent }}>
                <Outlet />
            </Box>
        </Box>
    );
};

export default MainLayout;
